import { Component, OnInit, Renderer2, OnDestroy, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import * as Rellax from 'rellax';
import {} from 'googlemaps';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, NgForm } from '@angular/forms';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `]
})


export class ComponentsComponent implements OnInit, OnDestroy {
    @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow
    infoContent = ''
  
    openInfo(marker: MapMarker, content) {
      this.infoContent = content
      this.infoWindow.open(marker)
    }
markers = []

   
      
    
    

   
    center: google.maps.LatLngLiteral
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom:10,
    minZoom: 1,
  }
    


form:FormGroup;

name;
email;
phone;
message;




showMap:boolean = true;
showCarousel: boolean = false;
    data : Date = new Date();

    page = 4;
    page1 = 5;
    page2 = 3;
    focus;
    focus1;
    focus2;

    date: {year: number, month: number};
    model: NgbDateStruct;
    iconBase = 'https://maps.google.com/mapfiles/kml/pushpin/';

    public isCollapsed = true;
    public isCollapsed1 = true;
    public isCollapsed2 = true;

    state_icon_primary = true;

    constructor( private http:HttpClient, private renderer : Renderer2, config: NgbAccordionConfig) {
        config.closeOthers = true;
        config.type = 'info';
    }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

   

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }

    
    
    
 

    ngOnInit() {
      var rellaxHeader = new Rellax('.rellax-header');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');

            this.center = {
              lat: 42.841167, 
              lng:  -73.772366
            }
            this.markers = [{
                position: {
                    lat: 42.869190, 
                    lng: -73.903947
                  },
                  label: {
                    color: 'red',
                    text: '210 Maple'
                  },
                  title: '210 Maple',
                  info: '',
                  options: {
                    animation: google.maps.Animation.BOUNCE,
                  }
            }, {

            position: {
                lat:  42.666459, 
                lng: -73.777028
              },
              label: {
                color: 'red',
                text: '9 Kent'
              },
              title: '9 Kent',
              info: '',
              options: {
                animation: google.maps.Animation.BOUNCE,
              },
        }, {

            position: {
                lat:  42.666175, 
                lng: -73.776612
              },
              label: {
                color: 'red',
                text: '2 Kent'
              },
              title: '2 Kent',
              info: '',
              options: {
                animation: google.maps.Animation.BOUNCE,
              },
        }
                
            ]
        

    }
    ngOnDestroy(){
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('index-page');
    }


    view(){
        this.showMap = false;
        this.showCarousel = true;
    }

    submit(){
            const headers = new HttpHeaders({'Content-Type': 'application/json'}) 
this.http.post('https://formspree.io/f/myybbklb', 
    {name: this.name, replyto: this.email, message: this.message },
    {'headers': headers}).subscribe(
        response => {
            console.log(response)
        }
    )
    this.name = null;
    this.email = null;
    this.message = null;
    this.phone = null;
    }
}

